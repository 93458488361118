// import React, { useState, useEffect } from "react";
// import "./counter.css";

// const COUNTDOWN_TARGET = new Date("2024-02-02T23:59:59");

// const getTimeLeft = () => {
//   const totalTimeLeft = COUNTDOWN_TARGET - new Date();
//   const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
//   const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
//   const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
//   const seconds = Math.floor((totalTimeLeft / 1000) % 60);

//   return { days, hours, minutes, seconds };
// };

// const Clock = () => {
//   const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(getTimeLeft());
//     }, 1000);

//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   return (
//     <div style={{ padding: "0 30px" }} className="countdown">
//       <div className="content">
//         {Object.entries(timeLeft).map((el) => {
//           const label = el[0];
//           const value = el[1];
//           return (
//             <div className="box" key={label}>
//               <div className="value">
//                 <span>{value}</span>
//               </div>
//               <span className="label sm:text-sm lg:text-lg"> {label} </span>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default Clock;

import React, { useState, useEffect } from "react";
import "./counter.css";

const COUNTDOWN_TARGET = new Date("2024-02-02T23:59:59");

const getTimeLeft = () => {
  const totalTimeLeft = COUNTDOWN_TARGET - new Date();
  const days = Math.max(Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24)), 0);
  const hours = Math.max(
    Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24),
    0
  );
  const minutes = Math.max(Math.floor((totalTimeLeft / (1000 * 60)) % 60), 0);
  const seconds = Math.max(Math.floor((totalTimeLeft / 1000) % 60), 0);

  return { days, hours, minutes, seconds };
};

const Clock = () => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <div style={{ padding: "0 30px" }} className="countdown">
      <div className="content">
        {Object.entries(timeLeft).map((el) => {
          const label = el[0];
          const value = el[1];
          return (
            <div className="box" key={label}>
              <div className="value">
                <span>{formatTime(value)}</span>
              </div>
              <span className="label sm:text-sm lg:text-lg"> {label} </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Clock;
