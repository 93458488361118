import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PastSpeaker2023 from "./PastSpeaker2023";
import PastSpeaker2022 from "./PastSpeaker2022";
import PastSpeaker2021 from "./PastSpeaker2021";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AnimationTitles from "../../components/functions/AnimationTitles";

const PastSpeakerMain = () => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white", // Set arrow color
          background: "rgba(0, 0, 0, 0.5)", // Set arrow background color
        }}
        onClick={onClick}
      >
        <FaChevronRight />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          marginLeft: "0px",
          color: "white", // Set arrow color
          background: "rgba(0, 0, 0, 0.5)", // Set arrow background color
        }}
        onClick={onClick}
      >
        <FaChevronLeft />
      </div>
    );
  };

  const CustomDots = (props) => {
    const { dots, currentSlide, onClick } = props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {dots.map((dot, index) => (
          <span
            key={index}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: index === currentSlide ? "#ddd" : "white",
              margin: "0 5px", // Adjust spacing between dots
              cursor: "pointer",
            }}
            onClick={() => onClick && onClick(index)}
          />
        ))}
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <CustomDots dots={dots} />,
  };

  return (
    <>
      <div style={{ marginLeft: "30px" }} className="content-center">
        <h1 className="lg:text-left sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mt-5 pt-10 mb-4 mx:auto px-4">
          <AnimationTitles title="Our Past Speakers" />
        </h1>
      </div>
      <Slider {...settings}>
        <div>
          <PastSpeaker2023 />
        </div>
        <div>
          <PastSpeaker2022 />
        </div>
        <div>
          <PastSpeaker2021 />
        </div>
      </Slider>
    </>
  );
};

export default PastSpeakerMain;
