// import React from "react";
// import { TalksData } from "./TalksData";

// const FilterButton = ({ talksItems, filterItems, setItems }) => {
//   return (
//     <div className="flex justify-center mt-4 mb-4 space-x-4">
//       {talksItems.map((val, index) => (
//         <button
//           key={index}
//           className="uppercase bg-black border border-white hover:bg-red-600 hover:text-white text-white font-normal py-2 px-4 rounded-md transition-colors duration-300 ease-in-out"
//           onClick={() => filterItems(val)}
//         >
//           {val}
//         </button>
//       ))}
//       <button
//         className="uppercase bg-black border border-white hover:bg-red-600 hover:text-white text-white font-normal py-2 px-4 rounded-md transition-colors duration-300 ease-in-out"
//         onClick={() => setItems(TalksData)}
//       >
//         All
//       </button>
//     </div>
//   );
// };

// export default FilterButton;

// import React from "react";
// import { TalksData } from "./TalksData";

// const FilterButton = ({ talksItems, filterItems, setItems }) => {
//   return (
//     <div className="flex flex-wrap justify-center mt-4 mb-4 space-y-2 sm:space-y-0 sm:space-x-4">
//       {talksItems.map((val, index) => (
//         <button
//           style={{ backgroundColor: "black", color: "white" }}
//           key={index}
//           className="uppercase mx-2 border border-white font-normal py-2 px-4 rounded-md transition-colors duration-300 ease-in-out"
//           onClick={() => filterItems(val)}
//           onMouseOver={(e) => {
//             e.currentTarget.style.backgroundColor = "white";
//             e.currentTarget.style.color = "black";
//           }}
//           onMouseOut={(e) => {
//             e.currentTarget.style.backgroundColor = "black";
//             e.currentTarget.style.color = "white";
//           }}
//         >
//           {val}
//         </button>
//       ))}
//       <button
//         style={{
//           marginTop: "10px",
//           backgroundColor: "black",
//           color: "white",
//           transition: "background-color 0.3s, color 0.3s",
//         }}
//         className="lg:py-2 uppercase bg-black border border-white hover:bg-yellow-500 hover:text-black text-white font-normal py-2 px-4 rounded-md transition-colors duration-300 ease-in-out"
//         onClick={() => setItems(TalksData)}
//         onMouseOver={(e) => {
//           e.currentTarget.style.backgroundColor = "white";
//           e.currentTarget.style.color = "black";
//         }}
//         onMouseOut={(e) => {
//           e.currentTarget.style.backgroundColor = "black";
//           e.currentTarget.style.color = "white";
//         }}
//       >
//         All
//       </button>
//     </div>
//   );
// };

// export default FilterButton;

import React from "react";
import { TalksData } from "./TalksData";

const FilterButton = ({ talksItems, filterItems, setItems }) => {
  return (
    <div className="flex overflow-x-auto p-4" style={{ maxWidth: "100%" }}>
      {talksItems.map((val, index) => (
        <button
          style={{ backgroundColor: "black", color: "white" }}
          key={index}
          className="uppercase mx-2 border border-white font-normal py-2 px-4 rounded-md transition-colors duration-300 ease-in-out"
          onClick={() => filterItems(val)}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "white";
            e.currentTarget.style.color = "black";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "black";
            e.currentTarget.style.color = "white";
          }}
        >
          {val}
        </button>
      ))}
    </div>
  );
};

export default FilterButton;
