import React from "react";
import AOS from "aos";
import AnimationTitles from "../../components/functions/AnimationTitles";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

export default function SecondBox() {
  return (
    <section
      style={{ backgroundColor: "#0e0c0e" }}
      class="text-gray-400 body-font p-10"
    >
      <div class="container   mx-auto flex flex-wrap">
        <div class="flex w-full mb-20 flex-wrap">
          <h1 class="sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4">
            <AnimationTitles title="Our Past Sponsors" />
          </h1>
        </div>
        <div class="flex flex-wrap md:-m-2 -m-1">
          <div class="flex flex-wrap w-1/2">
            <div data-aos="fade-down-right" class="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                class="w-full object-cover h-full object-center block"
                src={require("../../images/partners/frapp.png")}
              />
            </div>
            <div data-aos="fade-down" class="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                class="w-full object-cover h-full object-center block"
                src={require("../../images/partners/omkar.jpg")}
              />
            </div>
            <div data-aos="fade-up-right" class="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                class="w-full h-full object-cover object-center block"
                src={require("../../images/partners/img3.png")}
              />
            </div>
          </div>
          <div class="flex flex-wrap w-1/2">
            <div data-aos="fade-down-left" class="md:p-2 p-1 w-full">
              <img
                alt="gallery"
                class="w-full h-full object-fill object-center block"
                src={require("../../images/partners/img4.png")}
              />
            </div>
            <div data-aos="fade-up" class="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                class="w-full object-fill h-full object-center block"
                src={require("../../images/partners/IDBI-Bank-logo.jpg")}
              />
            </div>
            <div data-aos="fade-up-left" class="md:p-2 p-1 w-1/2">
              <img
                alt="gallery"
                class="w-full object-cover h-full object-center block"
                src={require("../../images/partners/saavn.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
