export const TalksData = [
  {
    id: 1,
    title: "Improvise, Adapt, Overcome, Master the art of rule bending",
    category: "Bending Rules",
    speaker_name: "Prateek Sethi",

    video_src: "https://www.youtube.com/embed/i46R9uq7kT0?si=R1o0_G3eXV6OV4Ft",
  },
  {
    id: 2,
    title: "Belief-The Key To Overcome Failure",
    category: "Bending Rules",
    speaker_name: "Nakash Aziz",

    video_src: "https://www.youtube.com/embed/Wqxzvd6RxE8?si=0DFNDGuL3Xz72yBh",
  },
  {
    id: 3,
    title:
      "Fortifying the Blockchain: The Critical Role of Security and Privacy",
    category: "Bending Rules",
    speaker_name: "Raj Aditya Kapoor",

    video_src: "https://www.youtube.com/embed/bnxUvLsptSo?si=lzxFr1sVaPMJWW5j",
  },
  {
    id: 4,
    title:
      "Breaking the Mold: How to Master the Art of Parenting in the Digital Age",
    category: "Bending Rules",
    speaker_name: "Tannaz Irani",

    video_src: "https://www.youtube.com/embed/k657UcFOE78?si=GFYG2NbkodN7AL3q",
  },
  {
    id: 5,
    title: "Unleashing the enchantment of Kuchipudi  ",
    category: "Bending Rules",
    speaker_name: "Aruna Varanasy",

    video_src: "https://www.youtube.com/embed/OdwGtrZGXMs?si=fmlmlGrZAmktRH2q",
  },
  {
    id: 6,
    title: "Paving the way for World free from Child Sexual Abuse",
    category: "Bending Rules",
    speaker_name: "Pooja Taparia",

    video_src: "https://www.youtube.com/embed/PfTWsw5bhGc?si=Q2NG-12YWmH7UWAj",
  },
  {
    id: 7,
    title: "Angels on Earth need surgeons too ",
    category: "Bending Rules",
    speaker_name: "Ashok Johari",
    video_src: "https://www.youtube.com/embed/umb1KKUf-c8?si=AuoCepaURRHBceeJ",
  },
  {
    id: 8,
    title: "Brewing the future of leadership",
    category: "Bending Rules",
    speaker_name: "Ajay Prabhakar",
    video_src:
      "https://www.youtube.com/embed/12hP-qlWL-w?si=BKj7OdIqrnx4lLUZ6R9uq7kT0?si=R1o0_G3eXV6OV4Ft",
  },
  {
    id: 9,
    title: "Failing is not wrong, repeating the same mistakes is ",
    category: "Bounce Back",
    speaker_name: "Lakshay Narula",
    video_src: "https://www.youtube.com/embed/9G4FmS1yARk?si=Vl-Tm2evgUBiIlM1",
  },
  {
    id: 10,
    title: "How to unlock opportunities for yourself? ",
    category: "Bounce Back",
    speaker_name: "Ayush Wadhwa",
    video_src: "https://www.youtube.com/embed/Tx_9uQJR8JQ?si=Tkf7kwA9Z_9F7UVW",
  },
  {
    id: 11,
    title: "You are lucky if your plans have failed  ",
    category: "Bounce Back",
    speaker_name: "Sushant Bindal",
    video_src: "https://www.youtube.com/embed/Wb4SDeh7XMU?si=wXAHOnom7HyslHFO",
  },
  {
    id: 12,
    title: "With perseverance, you are halfway through ",
    category: "Bounce Back",
    speaker_name: "Agatha Dias",
    video_src: "https://www.youtube.com/embed/OFaC9QrkefA?si=llNUJEMxYkqOt2-N",
  },
  {
    id: 13,
    title: "How to lead a maximising life  ",
    category: "Shifting gears",
    speaker_name: "Apurva Purohit",
    video_src: "https://www.youtube.com/embed/zZ6BtHMQKjw?si=UNpa6XH2wfZgM1aF",
  },
  {
    id: 14,
    title: "How I saved thousands of girls from sex trafficking   ",
    category: "Shifting gears",
    speaker_name: "Triveni Acharya",
    video_src: "https://www.youtube.com/embed/NDAxP2h0Lx8?si=4U6cjksd9Q-QEDTY",
  },
  {
    id: 15,
    title: "The evolution of the Music Industry: My journey  ",
    category: "Shifting gears",
    speaker_name: "Shibani Kashyap  ",
    video_src: "https://www.youtube.com/embed/r-YhU4Kpan4?si=X7ypWQivSNSAUjoe",
  },
  {
    id: 16,
    title: "Life in the IPS and CBI   ",
    category: "Shifting gears",
    speaker_name: "D.R. Karthikeyan",
    video_src: "https://www.youtube.com/embed/hG10I1ASHXI?si=z0-2MSL6XoaNUq64",
  },
  {
    id: 17,
    title:
      "An innovator's journey of disrupting the way surgeries are performed    ",
    category: "Shifting gears",
    speaker_name: "Vikas Karade ",
    video_src: "https://www.youtube.com/embed/C4NjDpsKBXc?si=W0bA5hsNr-0vSZQq",
  },
  {
    id: 18,
    title: "How to engineer your luck for success  ",
    category: "Rewind",
    speaker_name: "Col Subin Balakrishnan ",
    video_src: "https://www.youtube.com/embed/2NGFmu6IxDI?si=PirpCcmg0UE6xvGy",
  },
  {
    id: 19,
    title: "Striling puling and feeling  ",
    category: "Rewind",
    speaker_name: "V.S. Parthasarathy ",
    video_src: "https://www.youtube.com/embed/l9aHv-s1d0U?si=PrUy6r5SV8DIjJf0",
  },
  {
    id: 20,
    title: "How wasting time could help you find your passion ?  ",
    category: "Rewind",
    speaker_name: "Ankur and Akshay ",
    video_src: "https://www.youtube.com/embed/vuxSiz9kiZQ?si=xNEwZtGuru1OTAez",
  },
  {
    id: 21,
    title: "Adversities Introduce A Man To Himself ",
    category: "Rewind",
    speaker_name: "Bistriti Poddar  ",
    video_src: "https://www.youtube.com/embed/fHCIEfwpcuA?si=XIL6E0DWRwDAvhjb",
  },
  {
    id: 22,
    title: "Chasing News That Matters",
    category: "Rewind",
    speaker_name: "Tanvi Shukla ",
    video_src: "https://www.youtube.com/embed/Ae_SMNiXbws?si=azbu30BpHQMT1QAS",
  },
  {
    id: 23,
    title: "The Struggle For Equal Rights",
    category: "Rewind",
    speaker_name: "Nimesh Shetty  ",
    video_src: "https://www.youtube.com/embed/q4IzkwA32HA?si=alZ_LeMmmWCMKQcC",
  },
  {
    id: 24,
    title: "Taking A Chance On Yourself ",
    category: "Rewind",
    speaker_name: " Vibhas Sen",
    video_src: "https://www.youtube.com/embed/-Y-80hdGhos?si=_drt5y-w08Wd_4UM",
  },
  {
    id: 25,
    title: "Who chooses between a C- Section or a Natural Birth?  ",
    category: "Rewind",
    speaker_name: "Subarna Ghosh",
    video_src: "https://www.youtube.com/embed/QyTZAXp5egw?si=kjOKGPY922GFZZAi",
  },
  {
    id: 26,
    title: "Give back to society   ",
    category: "Rewind",
    speaker_name: "Ramesh Sippy",
    video_src: "https://www.youtube.com/embed/LG-kEKVdh4A?si=onsynCjYLFcwiJ-l",
  },
  {
    id: 27,
    title: "The Power Of Belief",
    category: "Rewind",
    speaker_name: "Rithvik Dhanjani",
    video_src: "https://www.youtube.com/embed/WsZDvVlBsVo?si=aRQcEqlNEgVME9Z4",
  },
  {
    id: 28,
    title: "Empowering People By Acceptance ",
    category: "Rewind",
    speaker_name: "Aashka Goradia",
    video_src: "https://www.youtube.com/embed/Fu3_FabRucI?si=rr3MUAI8N5JtNpT",
  },
  {
    id: 29,
    title: "Designing Careers: Get The Basics Right ",
    category: "Illogically logical",
    speaker_name: "Prabir Jha ",
    video_src: "https://www.youtube.com/embed/LzCSQA8la8g?si=K8DUP_DDtHUj-F1Q",
  },
  {
    id: 30,
    title: "Why Serving Society is Everyone's Responsibility  ",
    category: "Illogically logical",
    speaker_name: "Raveena Tandon  ",
    video_src: "https://www.youtube.com/embed/KTuGqFnwHbQ?si=lAEZTVxkSOYPfBe",
  },
  {
    id: 31,
    title: "What I have learned from 30 years in Journalism ",
    category: "Illogically logical",
    speaker_name: "Mark Manuel",
    video_src: "https://www.youtube.com/embed/P6l69_ImGNI?si=6iFXgnTEz3V1cW4O",
  },
  {
    id: 32,
    title: "India GenNext ! - How to retire rich ",
    category: "Illogically logical",
    speaker_name: "Mandar Jamsandekar ",
    video_src: "https://www.youtube.com/embed/WCOlY8SsmOM?si=ztV-YMO0DitPQJLG",
  },
  {
    id: 33,
    title: "Gujjubhai: How Theater gave me true success ",
    category: "Illogically logical",
    speaker_name: "Siddharth Randeria  ",
    video_src: "https://www.youtube.com/embed/k2hScPwi3S8?si=BrSjcM1VXx4LqRhE",
  },
  {
    id: 34,
    title: "Embrace your uniqueness: A glass harp performance ",
    category: "Illogically logical",
    speaker_name: "Gaurav kotian  ",
    video_src: "https://www.youtube.com/embed/rBoUvWitKIs?si=DG3ix7EJryJxytM9",
  },
  {
    id: 35,
    title: "Iktara: A Musical Success story",
    category: "Illogically logical",
    speaker_name: " Kavita Seth  ",
    video_src: "https://www.youtube.com/embed/2RG6E_zm848?si=lLgAZ662evr8n0Py",
  },
  {
    id: 36,
    title: "Coral Restoration: A process to prevent human extinction",
    category: "Illogically logical",
    speaker_name: "Dr. Chowdula Satyanarayana ",
    video_src: "https://www.youtube.com/embed/rpNjzZ9Ccx4?si=6lKH8gG0efiaO6RT",
  },
  {
    id: 37,
    title: "Food - From waste to plate ",
    category: "Illogically logical",
    speaker_name: "Gopal Hegde ",
    video_src: "https://www.youtube.com/embed/voOEz0NRFII?si=9V6ktkY-nnm83CbM",
  },
  {
    id: 38,
    title: "Teaching machines to read X-rays, CT Scans and MRI's",
    category: "Illogically logical",
    speaker_name: "Dr. Prashant Warier ",
    video_src: "https://www.youtube.com/embed/BPcqQtWiKM8?si=gWPSGFS7l4CCRzyA",
  },
  {
    id: 39,
    title: "Understanding the trauma of sexual assault ",
    category: "Illogically logical",
    speaker_name: "Rajat Mitra",
    video_src: "https://www.youtube.com/embed/RsiY9_U1IgI?si=0RpqGFcWiudkV99Y",
  },
  {
    id: 40,
    title: "Turning #Imperfect on it's head",
    category: "Presents for the present",
    speaker_name: "Sarah-Jane Dias ",
    video_src: "https://www.youtube.com/embed/qkGnz5z-74w?si=wCSR5M7OeHVPhwmG",
  },
  {
    id: 41,
    title: "Physics and Physicality ",
    category: "Presents for the present",
    speaker_name: "Dr. Siri Rama ",
    video_src: "https://www.youtube.com/embed/pmoISL0mFiI?si=Q_QG1qCOHK1MeuHj",
  },
  {
    id: 42,
    title: "Kitchens of compassion: Nourishing 1.5 million dreams every day ",
    category: "Presents for the present",
    speaker_name: "Shridhar Venkat ",
    video_src: "https://www.youtube.com/embed/m3hEL7VSi4I?si=98iKFN2loQvtPnxD",
  },
  {
    id: 43,
    title: "The interesting story of our educational system ",
    category: "Presents for the present",
    speaker_name: "Adhitya Iyer ",
    video_src: "https://www.youtube.com/embed/Fw1Fc_y_2Ek?si=c3ICgYXFXgIhPvBh",
  },
  {
    id: 44,
    title: "How to kiss joint pains goodbye ",
    category: "The unbounded paranoma",
    speaker_name: "Kaushik Bhojani  ",
    video_src: "https://www.youtube.com/embed/RoxQHmhBHQA?si=Jsk3G9u0HY7BMY4G",
  },
];
