import React from "react";
import AnimationTitles from "../../components/functions/AnimationTitles";

import PastSpeaker2023 from "./PastSpeaker2023";
import PastSpeakerMain from "./PastSpeakerMain";

const Speaker = () => {
  return (
    <>
      <div style={{ backgroundColor: "#0e0c0e" }}>
        <div className="">
          <h1 className="lg:text-left sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4 mx:auto  px-5">
            <AnimationTitles title="Our Speakers" />
          </h1>
        </div>

        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-18 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/1.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Abhishek Singh
                </h1>

                <h6 className="text-left text-gray-500">
                  IAS, ACTOR, SOCIAL ENTREPRENEUR
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Abhishek Singh, an IAS officer and former Deputy Commissioner of
                Delhi, is known for his artistic flair and administrative
                expertise. He has appeared in the web series "Delhi Crime" and
                also in music videos. His commitment to social causes is evident
                in his initiatives like mobile oxygen service during COVID-19,
                drive-in vaccination program, and SIGMA platform. He also
                initiated Project Hope, a government school revitalization
                initiative in east Delhi. Abhishek's ability to blend public
                service with social impact and the arts is a unique trait.
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 py-18 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/2.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Devang Thapliyal
                </h1>

                <h6 className="text-left text-gray-500">
                  OUTDOOR EDUCATOR & MOUNTAINEER
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Devang Thapliyal, an adventurous soul and Himalayan Rescuer
                whose narrative is a captivating tale of exploration and
                resilience. From his roots in Electronics and Communications
                Engineering to a thrilling transition from a Microsoft Software
                Engineer to a seasoned Outdoor Educator and Mountaineer,
                Devang's story is as diverse as the terrains he's conquered.
                Currently serving as a Senior Outdoor Instructor in Australia,
                he has left his mark globally with roles in Japan, the majestic
                Himalayas, and affiliations with renowned organizations like
                CLAW Global, OWLS Adventures, National Geographic Survey of
                India. Holding certifications in Advanced Mountaineering and
                Rock Climbing, Devang is a true adventurer with global
                experience.
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 pt-20 pb-15 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/3.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Rohan Patoley
                </h1>

                <h6 className="text-left text-gray-500">
                  DIRECTOR WITH NAT GEO & EX TRAVELXP HOST
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Rohan Patoley, a seasoned Creative Director and Travel/Food Show
                Presenter with over 17 years of experience, currently serves as
                a Director at National Geographic and Co-Partner with
                Premnandini Films. His extensive career includes roles at Zee
                TV, Travelxp, E24, ESPN, and now Hotstar. Rohan, a recognized
                podcast presenter and YouTuber, has conceptualized, directed,
                and hosted over 400 TV episodes across 36 countries. With
                nominations at the Indian Television Awards, he stands out for
                his award-winning skills in creative direction, content
                creation, and management.
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 py-18 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/5.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Ridhi Kosla Jalan
                </h1>

                <h6 className="text-left text-gray-500">
                  INTERIOR DESIGNER & DESIGN INFLUENCER
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Ridhi Khosla Jalan, India's Premium Design Influencer and
                Interior Designer,has collaborated with global brands like
                Maison & Objet, Smeg, and Godrej Security Solutions. Her
                exclusive interview series featuring personalities such as
                Padmashri awardee Sunita Kohli and Ayushmann Khurana.Ridhi holds
                the distinction of being the only influencer personally invited
                by the Ministry of Culture for the exclusive opening of PMO's
                Art Gallery. Additionally, she had the privilege of an exclusive
                preview of the launch of Swadesh, Nita Ambani's initiative.
                Ridhi's design prowess and influential network position her as a
                key influencer actively shaping India's architecture, design,
                and cultural narrative
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 pt-20 pb-18 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/6.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Dr. Hemant Nanavati
                </h1>

                <h6 className="text-left text-gray-500">
                  PROFESSOR AT IIT BOMBAY
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Dr. Hemant Nanavati, Distinguished Core Faculty at IIT Bombay,
                his contributions to Chemical Engineering earned him the
                Sigma-Xi PhD Thesis Award. With expertise in Network Elasticity,
                Membranes, and more, he holds patents, including one for
                high-crystallinity lactic acid polymers. An acclaimed academic,
                his work on glassy polymers’ mechanical properties stands out.
                Dr. Nanavati’s ongoing contributions shape the landscape of
                Chemical Engineering and Polymer Science.
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 py-18 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/4.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Abhishek Bhattacharya
                </h1>

                <h6 className="text-left text-gray-500">BLOCKCHAIN AUTHOR</h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Abhishek Bhattacharya, a trailblazing serial entrepreneur
                specializing in FinTech and BlockchainTech. Renowned as a Forbes
                30 under 30 honoree, 4-times Author, Ethereum Foundation Fellow,
                and UNDP INSPIRO Fellow, Abhishek's impact extends globally. At
                Brú, he transformed lives by integrating Blockchain into
                agriculture, connecting 30,000+ farmers to global DeFi lenders
                with US $600 Million+ in tokenized assets. An instrumental
                figure at Brú, he raised US $350,000 in VC funds and secured US
                $200,000+ in equity-free grants. Abhishek has taught blockchain
                to thousands globally, and spoken at prestigious events like
                Singapore Fintech Festival, SmartCon New York, DEVCON, and
                cNFTCon Las Vegas.
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 py-18 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/7.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Pramoud Rao
                </h1>

                <h6 className="text-left text-gray-500">
                  EX PROMOTOR & ZICOM ELECTRONIC SECURITY SYSTEM
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Pramoud Rao, a security innovator with over 29 years of
                experience, transformed Zicom Electronic Security Systems into a
                leading global provider. He launched India's first security
                command station, pioneered cloud-based security services, and
                introduced Pagers to our country. Currently serving as a mentor
                at Zimaxx Techsolutions Pvt Ltd. Recognized for his expertise in
                pet safety tech trends, where he highlights innovative solutions
                like QR code ID tags, NFC tags and 24/7 pet safety stations.
              </p>
            </div>
          </div>

          <div className="container mx-auto flex px-5 py-10 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={require("../../images/speakers/8.png")}
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <div>
                <h1
                  style={{
                    border: "4px solid #ffd700",
                    padding: "10px",
                  }}
                  className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"
                >
                  Mandar Karanjkar
                </h1>

                <h6 className="text-left text-gray-500">
                  CLASSICAL VOCALIST, AUTHOR, SPEAKER
                </h6>
              </div>

              <p className="text-white mb-8 leading-relaxed text-justify">
                Mandar Karanjkar, co-founder of Baithak Foundation, a nonprofit
                dedicated to preserving Indian Traditional Music and providing
                music access to underprivileged children which is based in Pune.
                Mandar wears many hats – classical vocalist, communications
                consultant, and speaker. With over 20 renowned brands benefiting
                from his strategic communication interventions.He has also
                conducted leadership training programs for both startups and
                large conglomerates. His literary achievements include 'The
                Kabir Way' and co-authoring 'Pi- Premachi Kimmat Kay?'.Mandar's
                impactful interviews, collaboration with 100+ musicians,and
                engagement with 50+ schools showcase his diverse expertise in
                breaking barriers in music and thought leadership
              </p>
            </div>
          </div>
        </section>
      </div>
      <PastSpeakerMain />
      <h4 className="text-center text-white m-2">Swipe right</h4>
    </>
  );
};

export default Speaker;
