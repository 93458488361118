import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Footer() {
  return (
    <footer>
      <Container className="">
        <div className="d-flex justify-content-between flex-column flex-md-row flex-wrap pt-5 pb-4">
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={require("../../images/logo/tedxcrcefooterlogo.png")}
              alt="logo"
              className="mb-3"
            />
            <p className="gray-100">
              This independent TEDx event is operated under license from TED.
            </p>
            <Link className="link-warning link-underline-opacity-0" to={""}>
              tedxcrce@gmail.com
            </Link>
          </motion.div>
          <span className="d-block d-md-none"></span>
          <motion.div
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className="d-flex"
          >
            <div className="me-5">
              <h6 className="gray-100 text-uppercase mb-2 fw-normal">Pages</h6>
              <ul className="p-0">
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="/gallery"
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="/talks"
                  >
                    Talks
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="/speakers"
                  >
                    Speakers
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="/partners"
                  >
                    Partners
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="/contact"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h6 className="gray-100 text-uppercase mb-2 fw-normal">
                Socials
              </h6>
              <ul className="p-0">
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="  https://twitter.com/tedxcrce"
                  >
                    Twitter
                  </a>
                </li>

                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="https://www.instagram.com/tedxcrce/"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    style={{ textDecorationSkip: "none", color: "inherit" }}
                    href="https://www.linkedin.com/company/tedxcrce/"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="d-flex justify-content-between flex-column flex-md-row flex-wrap gray-100 pt-3"
        >
          <p className="pb-5">© 2024 TEDxCRCE. All rights reserved</p>
        </motion.div>
      </Container>
    </footer>
  );
}

export default Footer;
