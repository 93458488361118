// import React from "react";
// import tedxcrceImage from "../../images/logo/TEDxCRCE.svg";
// import AnimationTitles from "../../components/functions/AnimationTitles";
// const Herotemp = () => {
//   return (
//     <div
//       style={{
//         content: "",
//         position: "absolute",
//         display: "block",
//         top: "0",
//         left: "-10%",
//         width: "150px",
//         height: "150px",
//         backgroundColor: "#f6d935",
//         borderRadius: "50%",
//         filter: "blur(12rem)",
//         zIndex: "-999",
//         webkitBorderRadius: "50%",
//         mozBorderRadius: "50%",
//         msBorderRadius: "50%",
//         oborderRadius: "50%",
//         webkitFilter: "blur(12rem)",
//       }}
//     >
//       <section class="text-gray-600 body-font">
//         <div class="container mx-auto flex px-5 pt-0 pb-14 md:flex-row flex-col items-center">
//           <div class=" lg:flex-grow md:w-1/2 lg:mt-0 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
//             <h1
//               style={{ fontSize: "30px" }}
//               class="title-font sm:text-4xl text-4xl mb-2 font-sans text-white"
//             >
//               <AnimationTitles
//                 style={{ fontSize: "30px" }}
//                 title="Cracks in the Canvas"
//               />
//             </h1>
//             <p class="mb-8 leading-relaxed text-justify">
//               The theme for this year is "Cracks in the Canvas." In a world that
//               often values perfection, we tend to overlook the beauty found in
//               our imperfections. Similar to the cracks in a painting that add
//               depth and character, our flaws define who we are. Instead of
//               chasing after unachievable perfection, l
//             </p>
//             <div class="flex justify-center">
//               <button
//                 style={{ backgroundColor: "#f6d935" }}
//                 class="inline-flex text-black border-0 py-2 px-6 focus:outline-none rounded text-lg"
//               >
//                 Explore
//               </button>
//             </div>
//           </div>
//           <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
//             <img
//               class="object-cover object-center rounded"
//               alt="hero"
//               src={tedxcrceImage}
//             />
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Herotemp;

import React, { useState, useEffect } from "react";

import Video from "../../images/logo/TEDxCRCEHeroVideo2.mp4";
import AnimationTitles from "../../components/functions/AnimationTitles";

const Herotemp = () => {
  const [displayStyle, setDisplayStyle] = useState(
    window.innerWidth <= 700 ? "flex" : "block"
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setDisplayStyle(window.innerWidth <= 700 ? "flex" : "block");
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      style={{ backgroundColor: "#0e0c0e" }}
      className="relative overflow-hidden pt-8"
    >
      {/* Content */}
      <div className=" container mx-auto flex px-5 pt-0 pb-5 md:flex-row flex-col items-center relative z-10 lg:items-center lg:justify-center">
        {isMobile ? (
          <>
            <div className="lg:max-w-full lg:w-full lg:mr-10 sm:mb-2 md:w-1/2 w-5/6 relative z-10">
              <video
                className="w-full h-full object-cover object-center rounded"
                alt="hero"
                autoPlay
                muted
              >
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="flex items-center justify-center">
              <div
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                  marginLeft: "20px",
                }}
                className=""
              >
                <div
                  style={{
                    display: displayStyle,
                  }}
                >
                  {/* Date */}
                  <div style={{ paddingRight: "30px" }}>
                    <h6
                      style={{
                        fontSize: "20px",
                        paddingBottom: "20px",
                        padding: "0",
                        lineHeight: "normal",
                        // whiteSpace: "nowrap",
                      }}
                      className="title-font sm:text-xl text-4xl sm:mt-2 mb-2 font-sans text-white"
                    >
                      February 2024
                    </h6>
                    <h1
                      style={{
                        fontSize: "70px",
                        color: "#ffd700",
                        padding: "0",
                        lineHeight: "50%",
                      }}
                      className=" title-font sm:text-2xl  sm:mt-2  font-sans "
                    >
                      03
                    </h1>
                  </div>

                  {/* Date end */}

                  {/* theme start */}
                  <div style={{ whiteSpace: "nowrap" }} className="">
                    <h6
                      style={{
                        fontSize: isMobile ? "30px" : "70px",
                        color: "white",
                        paddingBottom: " 10px",
                        lineHeight: "90%",
                      }}
                    >
                      Cracks in the
                    </h6>

                    <h6
                      style={{
                        fontSize: isMobile ? "30px" : "70px",
                        color: "white",
                        paddingBottom: " 10px",
                        lineHeight: "0%",
                      }}
                    >
                      canvas
                    </h6>

                    {/* <h1
                      style={{
                        fontSize: isMobile ? "30px" : "70px",
                        color: "white",
                        paddingBottom: " 20px",
                      }}
                      className="title-font sm:text-lg sm:mt-2 mb-2 font-sans"
                    >
                      Canvas
                    </h1> */}

                    <h2
                      style={{
                        fontSize: "20px",
                        lineHeight: "200%",
                        paddingTop: "10px",
                      }}
                      className="title-font sm:text-4xl text-4xl sm:mt-2 mb-2 font-sans text-white"
                    >
                      Fr.CRCE, Bandra
                    </h2>
                  </div>
                  {/* theme end */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center justify-center">
              <div
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                }}
                className=""
              >
                <div
                  style={{
                    display: displayStyle,
                  }}
                >
                  {/* Date */}
                  <div>
                    <h6
                      style={{ fontSize: "20px", paddingBottom: "20px" }}
                      className="title-font sm:text-xl text-4xl sm:mt-2 mb-2 font-sans text-white"
                    >
                      February 2024
                    </h6>
                    <h1
                      style={{
                        fontSize: "100px",
                        color: "#ffd700",
                      }}
                      className="title-font sm:text-2xl  sm:mt-2 mb-2 font-sans "
                    >
                      03
                    </h1>
                  </div>
                  <br />
                  {/* Date end */}
                  <br />
                  {/* theme start */}
                  <div className="sm:pl-15">
                    <h1
                      style={{
                        fontSize: isMobile ? "50px" : "70px",
                        color: "white",
                        paddingBottom: " 10px",
                      }}
                    >
                      Cracks in the
                    </h1>

                    <h1
                      style={{
                        fontSize: isMobile ? "50px" : "70px",
                        color: "white",
                        paddingBottom: " 20px",
                      }}
                      className="title-font sm:text-lg sm:mt-2 mb-2 font-sans"
                    >
                      Canvas
                    </h1>

                    <h2
                      style={{ fontSize: "20px" }}
                      className="title-font sm:text-4xl text-4xl sm:mt-2 mb-2 font-sans text-white"
                    >
                      Fr.CRCE, Bandra
                    </h2>
                  </div>
                  {/* theme end */}
                </div>
              </div>
            </div>

            <div className="lg:max-w-2xl lg:w-full lg:mr-10 sm:mb-2 md:w-1/2 w-5/6 relative z-10">
              <video
                className="w-full h-full object-cover object-center rounded"
                alt="hero"
                autoPlay
                muted
              >
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Herotemp;
