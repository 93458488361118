// // import Container from "react-bootstrap/Container";
// // import Nav from "react-bootstrap/Nav";
// // import Navbar from "react-bootstrap/Navbar";
// // import logo from "../../images/logo/tedxcrcelogo.png";
// // import { Button } from "react-bootstrap";
// // import "bootstrap/dist/css/bootstrap.min.css";
// // import "./navbar.css";

// // function NavBar() {
// //   return (
// //     <Navbar expand="lg" className="py-3">
// //       <Container>
// //         <Navbar.Brand href="/gallery" className="me-lg-5">
// //           <img className="logo" src={logo} alt="logo" />
// //         </Navbar.Brand>
// //         <Navbar.Toggle aria-controls="navbarScroll" />
// //         <Navbar.Collapse id="navbarScroll">
// //           <Nav className="me-auto my-2 my-lg-0" navbarScroll>
// //             <Nav.Link style={{ color: "white" }} href="#action1">
// //               Marketplace
// //             </Nav.Link>
// //             <Nav.Link className="text-white px-lg-3" href="#action1">
// //               About Us
// //             </Nav.Link>
// //             <Nav.Link className="text-white" href="#action1">
// //               Developers
// //             </Nav.Link>
// //           </Nav>
// //         </Navbar.Collapse>
// //         <div className="d-flex align-items-center order">
// //           <span className="line d-lg-inline-block d-none"></span>
// //           <i className="fa-regular fa-heart"></i>
// //           <Button
// //             variant="primary"
// //             className="btn-warning d-none d-lg-inline-block"
// //           >
// //             Connect Wallet
// //           </Button>
// //         </div>
// //       </Container>
// //     </Navbar>
// //   );
// // }

// // export default NavBar;
import React, { useState } from "react";
import logo from "../../images/logo/tedxcrcelogo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      // style={{ backgroundColor: "#09090a" }}

      className=" p-4 font-sans  top-0 z-50 sm: mb-3"
    >
      <div className="container mx-auto flex justify-between items-center">
        <div>
          <div>
            <a href="/">
              <img className="sm:mx-0 w-full h-12" src={logo} alt="logo" />
            </a>
          </div>
        </div>

        {/* Hamburger menu icon for mobile */}
        <div className="md:hidden">
          <button
            onClick={toggleNavbar}
            className="text-white hover:text-gray-300 focus:outline-none"
          >
            <svg
              className="w-6 h-10 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 4H22V6H2V4ZM2 11H22V13H2V11ZM2 18H22V20H2V18Z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 5H20V7H4V5ZM4 10H20V12H4V10ZM4 15H20V17H4V15Z"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Menu links for desktop */}
        <div className="hidden font-sans mx-7 md:flex md:space-x-20">
          <a
            style={{ textDecoration: "none" }}
            href="/"
            className="text-white text-lg font-sans hover:text-yellow-500"
          >
            Home
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="/gallery"
            className="text-white font-sans text-lg hover:text-yellow-500"
          >
            Gallery
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="/talks"
            className="text-white font-sans text-lg hover:text-yellow-500"
          >
            Talks
          </a>
          {/* <a
            style={{ textDecoration: "none" }}
            href="/teams"
            className="text-white font-sans text-lg hover:text-yellow-500"
          >
            Team
          </a> */}
          <a
            style={{ textDecoration: "none" }}
            href="/speakers"
            className="text-white font-sans text-lg hover:text-yellow-500"
          >
            Speakers
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="/partners"
            className="text-white font-sans text-lg hover:text-yellow-500"
          >
            Partners
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="/contact"
            className="text-white font-sans text-lg hover:text-yellow-500"
          >
            Contact
          </a>

          <a style={{ textDecoration: "none" }} href="/register">
            <button
              style={{
                backgroundColor: "#ffd700",
                padding: "5px 30px",
                color: "black",
              }}
            >
              Register
            </button>
          </a>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="flex flex-col font-sans space-y-4 mt-4">
            <a
              style={{ textDecoration: "none" }}
              href="/"
              className="text-white text-lg hover:text-yellow-500"
            >
              Home
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="/gallery"
              className="text-white text-lg hover:text-yellow-500"
            >
              Gallery
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="/talks"
              className="text-white text-lg hover:text-yellow-500"
            >
              Talks
            </a>
            {/* <a
              style={{ textDecoration: "none" }}
              href="/teams"
              className="text-white text-lg hover:text-yellow-500"
            >
              Team
            </a> */}
            <a
              style={{ textDecoration: "none" }}
              href="/speakers"
              className="text-white text-lg hover:text-yellow-500"
            >
              Speakers
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="/partners"
              className="text-white text-lg hover:text-yellow-500"
            >
              Partners
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="/contact"
              className="text-white text-lg hover:text-yellow-500"
            >
              Contact
            </a>
            <a href="/register">
              <button
                style={{
                  backgroundColor: "#ffc107",
                  padding: "10px 30px",
                  color: "black",
                  width: "100%",
                }}
                className="font-sans text-lg"
              >
                Register
              </button>
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
