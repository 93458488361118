import React from "react";

import sarah from "../../images/pastspeaker/sarahfinal.jpg";
import mark from "../../images/pastspeaker/Mark.jpg";
import gaurav from "../../images/pastspeaker/gaurav.jpg";
import prashant from "../../images/pastspeaker/prashant.png";
import siddhart from "../../images/pastspeaker/siddharth.jpg";
import aditya from "../../images/pastspeaker/adhitya1f.jpg";
import prabhir from "../../images/pastspeaker/Prabirf.jpg";
import sameer from "../../images/pastspeaker/sameer.jpg";
import kalpana from "../../images/pastspeaker/kpfinal.jpg";
import triveni from "../../images/pastspeaker/trivenif.jpg";
import vibhash from "../../images/pastspeaker/vibhas.jpg";
import rhitvik from "../../images/pastspeaker/rhitvik.jpg";
import ak2 from "../../images/pastspeaker/ak2.jpg";
import subhin from "../../images/pastspeaker/su.jpg";
import ramesh from "../../images/pastspeaker/sippy.jpg";

import AnimationTitles from "../../components/functions/AnimationTitles";

const PastSpeaker2022 = () => {
  return (
    <div>
      <section
        style={{ backgroundColor: "#0e0c0e" }}
        class="text-gray-400 body-font"
      >
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="raveena"
                  class="object-cover object-center w-full h-full block"
                  src={sarah}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Sarah Jane Dias
                </h2>
                <p class="mt-1">INDIAN ACTRESS</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={kalpana}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Kalpana Morparia
                </h2>
                <p class="mt-1"> INDIAN BANKER</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={triveni}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Triveni Acharya
                </h2>
                <p class="mt-1">INDIAN JOURNALIST AND ACTIVIST</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={vibhash}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Vibhas Sen
                </h2>
                <p class="mt-1">PARALYMPIC FENCER&DIGITAL ADVERTISER</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={rhitvik}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Rhitvik Dhanjani
                </h2>
                <p class="mt-1">ACTOR</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-75 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={ak2}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Ankur and Akshay
                </h2>
                <p class="mt-1"> SCOOPWHOOP PERFORMERS</p>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={subhin}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Col. Subin Balakrishnan
                </h2>
                <p class="mt-1">EX INDIAN ARMY</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-75 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={ramesh}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Ramesh Sippy
                </h2>
                <p class="mt-1">RENOWNED DIRECTOR & PADMA SHRI AWARDEE</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PastSpeaker2022;
