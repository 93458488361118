import React from "react";
import prateek from "../../images/pastspeaker/prateekf.jpg";
import ashok from "../../images/pastspeaker/ashok.jpg";
import aruna from "../../images/pastspeaker/aruna.jpeg";
import tannaz from "../../images/pastspeaker/tannaz.jpg";
import ajay from "../../images/pastspeaker/ajay.jpeg";
import pooja from "../../images/pastspeaker/pooja.png";
import raj from "../../images/pastspeaker/raj.jpg";
import nakash from "../../images/pastspeaker/nakash.jfif";
import raveena from "../../images/pastspeaker/raveena.png";
import mark from "../../images/pastspeaker/Mark.jpg";
import gaurav from "../../images/pastspeaker/gaurav.jpg";
import prashant from "../../images/pastspeaker/prashant.png";
import siddhart from "../../images/pastspeaker/siddharth.jpg";
import aditya from "../../images/pastspeaker/adhitya1f.jpg";
import prabhir from "../../images/pastspeaker/Prabirf.jpg";
import sameer from "../../images/pastspeaker/sameer.jpg";

import AnimationTitles from "../../components/functions/AnimationTitles";

const PastSpeaker2022 = () => {
  return (
    <div>
      <section
        style={{ backgroundColor: "#0e0c0e" }}
        class="text-gray-400 body-font"
      >
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="raveena"
                  class="object-cover object-center w-full h-full block"
                  src={raveena}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Raveena Tandon
                </h2>
                <p class="mt-1">ACTRESS</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={mark}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Mark Manuel
                </h2>
                <p class="mt-1">JOURNALISM, INDIA'S SENIOR MOST JOURNALIST.</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={gaurav}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Gaurav Kotian
                </h2>
                <p class="mt-1">MUSICIAN- GLASS HARP</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={prashant}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Dr. Prashant Warier
                </h2>
                <p class="mt-1">CEO - QUIRE.AI</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={siddhart}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Siddhart Randeria
                </h2>
                <p class="mt-1">ACTOR</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={aditya}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Adhitya Iyer
                </h2>
                <p class="mt-1">AUTHOR</p>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={prabhir}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Prabir Jha
                </h2>
                <p class="mt-1">FOUNDER & CEO OF PRABIR JHA PEOPLE ADVISORY</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={sameer}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Sameer Ganpathy
                </h2>
                <p class="mt-1">CEO STUDIOVERSE ANTHILL STUDIO</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PastSpeaker2022;
