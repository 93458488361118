import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import AnimationTitles from "../../components/functions/AnimationTitles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faClock,
  faCalendarDays,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

// const { Container } = require("react-bootstrap");

function Join() {
  return (
    <div className="join">
      <Container>
        <Swiper
          grabCursor={true}
          spaceBetween={50}
          slidesPerView={1}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            998: {
              slidesPerView: 3,
            },
            1198: {
              slidesPerView: 4,
            },
          }}
          className="mySwiper"
        >
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
            <h4 className="gray-100 mb-5">1</h4>
            {/* <img src={require("../images/illustration/01.webp")} alt="img" /> */}
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{
                fontSize: "3rem",
                color: "#f4b000",
                paddingBottom: "10px",
              }}
            />
            <AnimationTitles
              title="Venue"
              className="text-white mb-4 mt-5 h4"
            />
            <p className="gray-50">
              Samvaad Auditorium Fr. Agnel Technical Complex Bandra West
            </p>
          </SwiperSlide>
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
            <h4 className="gray-100 mb-5">2</h4>
            <FontAwesomeIcon
              icon={faClock}
              style={{
                fontSize: "3rem",
                color: "#f4b000",
                paddingBottom: "10px",
              }}
            />
            <AnimationTitles title="Time" className="text-white mb-4 mt-5 h4" />
            <p className="gray-50">2 PM IST</p>
          </SwiperSlide>
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
            <h4 className="gray-100 mb-5">3</h4>
            <FontAwesomeIcon
              icon={faCalendarDays}
              style={{
                fontSize: "3rem",
                color: "#f4b000",
                paddingBottom: "10px",
              }}
            />
            <AnimationTitles title="Date" className="text-white mb-4 mt-5 h4" />
            <p className="gray-50">3 February 2024</p>
          </SwiperSlide>
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
            <h4 className="gray-100 mb-5">4</h4>
            <FontAwesomeIcon
              icon={faBook}
              style={{
                fontSize: "3rem",
                color: "#f4b000",
                paddingBottom: "10px",
              }}
            />
            <AnimationTitles
              title="Insider Guide"
              className="text-white mb-4 mt-5 h4"
            />
            <p className="gray-50">Click here to download</p>
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
}

export default Join;
