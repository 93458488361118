import React from "react";
import { useState } from "react";

import { TalksData } from "./TalksData";
import TalksCard from "./Talkscard";
import FilterButton from "./FilterButton";
import AnimationTitles from "../../components/functions/AnimationTitles";

const Talks = () => {
  const [items, setItems] = useState(TalksData);
  const talksItems = [...new Set(TalksData.map((val) => val.category))];

  const filterItems = (cat) => {
    const newItems = TalksData.filter((newval) => newval.category === cat);
    setItems(newItems);
  };
  return (
    <div>
      <div className="col-span-full flex justify-center">
        <h1
          style={{ color: "#ffc107" }}
          className="font-sans text-2xl md:text-2xl  mb-4 "
        >
          <AnimationTitles title="TEDxCRCE TALKS" />
        </h1>
      </div>

      <div className="">
        <FilterButton
          talksItems={talksItems}
          filterItems={filterItems}
          setItems={setItems}
        />
        <TalksCard item={items} />
      </div>
    </div>
  );
};

export default Talks;
