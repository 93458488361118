import AnimationTitles from "../../components/functions/AnimationTitles";
import React, { useState, useEffect } from "react";

const Gallery = () => {
  const [currentDiv, setCurrentDiv] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDiv((prevDiv) => (prevDiv % 3) + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section className="text-gray-600 body-font">
        {/* 111 */}
        <div>
          <div
            style={{ display: currentDiv === 1 ? "block" : "none" }}
            className="container px-5 py-24 mx-auto flex flex-wrap"
          >
            <div className="flex w-full mb-20 flex-wrap">
              <h1 className="sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4">
                <AnimationTitles title="Speaker Gallery" />
              </h1>
            </div>
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/galleryspeaker1.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/galleryimg3.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={require("../../images/gallery/galleryspeakerraveena.jpg")}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={require("../../images/gallery/galleryimg8.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/galleryimg4.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/galleryimg5.jpg")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 2222 */}
          <div
            style={{ display: currentDiv === 2 ? "block" : "none" }}
            className="container px-5 py-24 mx-auto flex flex-wrap"
          >
            <div className="flex w-full mb-20 flex-wrap">
              <h1 className="sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4">
                <AnimationTitles title="Speaker Gallery" />
              </h1>
            </div>
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide2/gallerymark.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide2/galleryslide2.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={require("../../images/gallery/slide2/galleryslide2_3.jpg")}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={require("../../images/gallery/slide2/galleryslide2_4.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide2/galleryslide2_5.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide2/galleryslide2_6.jpg")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 333 */}

          <div
            style={{ display: currentDiv === 3 ? "block" : "none" }}
            className="container px-5 py-24 mx-auto flex flex-wrap"
          >
            <div className="flex w-full mb-20 flex-wrap">
              <h1 className="sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4">
                <AnimationTitles title="Speaker Gallery" />
              </h1>
            </div>
            <div className="flex flex-wrap md:-m-2 -m-1">
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide3/galleryslide3_1.jpg")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide3/galleryslide3_2.png")}
                  />
                </div>
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={require("../../images/gallery/slide3/galleryslide3_3.jpg")}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-1/2">
                <div className="md:p-2 p-1 w-full">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center block"
                    src={require("../../images/gallery/slide3/galleryslide3_4.png")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide3/galleryslide3_5.png")}
                  />
                </div>
                <div className="md:p-2 p-1 w-1/2">
                  <img
                    alt="gallery"
                    className="w-full object-cover h-full object-center block"
                    src={require("../../images/gallery/slide3/galleryslide3_6.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Performance gallery */}
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="flex w-full mb-20 flex-wrap">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4">
              <AnimationTitles title="Performance Gallery" />
            </h1>
          </div>
          <div className="flex flex-wrap md:-m-2 -m-1">
            <div className="flex flex-wrap w-1/2">
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={require("../../images/gallery/galleryperformance1.jpg")}
                />
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={require("../../images/gallery/galleryperformance2.jpg")}
                />
              </div>
              <div className="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  className="w-full h-full object-cover object-center block"
                  src={require("../../images/gallery/galleryperformance3.jpg")}
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/2">
              <div className="md:p-2 p-1 w-full">
                <img
                  alt="gallery"
                  className="w-full h-full object-cover object-center block"
                  src={require("../../images/gallery/galleryperformance4.jpg")}
                />
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={require("../../images/gallery/galleryperformance5.jpg")}
                />
              </div>
              <div className="md:p-2 p-1 w-1/2">
                <img
                  alt="gallery"
                  className="w-full object-cover h-full object-center block"
                  src={require("../../images/gallery/galleryperformance6.jpg")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
