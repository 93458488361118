// import React from "react";

// const Theme = () => {
//   return (
//     <div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           textAlign: "center",
//           alignContent: "center",
//           color: "white",
//         }}
//       >
//         <h1>What is cracks in the canvas?</h1>
//       </div>
//       <div
//         style={{
//           margin: "10px auto",

//           display: "flex",
//           alignItems: "center",
//         }}
//         className="lg:w-2/3 sm:w-full"
//       >
//         <p
//           style={{
//             color: "white",
//           }}
//           className="text-justify sm:w-full px-5"
//         >
//           The theme for this year is "Cracks in the Canvas." In a world that
//           often values perfection, we tend to overlook the beauty found in our
//           imperfections. Similar to the cracks in a painting that add depth and
//           character, our flaws define who we are. Instead of chasing after
//           unachievable perfection, let's cherish our individuality and the
//           unique qualities that make us stand out. Just as a hidden gem can be
//           discovered in unexpected places, our true beauty often resides within
//           our imperfections. The conference aims to celebrate the diversity of
//           human experiences and perspectives, fostering a world where flaws are
//           embraced and honored.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Theme;

import React from "react";

const Theme = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignContent: "center",
          color: "white",
          position: "relative", // Added for positioning pseudo-elements
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <h1 style={{ fontSize: "40px" }} className="font-semibold">
          What is Cracks in the Canvas?
        </h1>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "50%", // Adjust this value as needed
            borderTop: "5px solid gold",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: "50%", // Adjust this value as needed
            borderBottom: "5px solid gold",
          }}
        ></div>
      </div>
      <div
        style={{
          margin: "20px auto",
          display: "flex",
          alignItems: "center",
        }}
        className="lg:w-2/3 sm:w-full"
      >
        <p
          style={{
            color: "white",
          }}
          className="text-justify sm:w-full px-5"
        >
          The theme for this year is "Cracks in the Canvas." In a world that
          often values perfection, we tend to overlook the beauty found in our
          imperfections. Similar to the cracks in a painting that add depth and
          character, our flaws define who we are. Instead of chasing after
          unachievable perfection, let's cherish our individuality and the
          unique qualities that make us stand out. Just as a hidden gem can be
          discovered in unexpected places, our true beauty often resides within
          our imperfections. The conference aims to celebrate the diversity of
          human experiences and perspectives, fostering a world where flaws are
          embraced and honored.
        </p>
      </div>
    </div>
  );
};

export default Theme;
