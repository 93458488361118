import { Button, Container } from "react-bootstrap";
import { motion } from "framer-motion";
import AnimationTitles from "../../components/functions/AnimationTitles";

function AboutUs() {
  return (
    <div className="about">
      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap sm:px-8">
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h3 className="text-white text-lg">What is TED?</h3>
          {/* <AnimationTitles title="What is TED?" className="title" /> */}
          <p className=" text-justify gray-50 mb-5">
            TED is a nonprofit organization devoted to Ideas Worth Spreading.
            Started as a four-day conference in California 30 years ago, TED has
            grown to support its mission with multiple initiatives. The two
            annual TED Conferences invite the world's leading thinkers and doers
            to speak for 18 minutes or less. Many of these talks are then made
            available, free, at TED.com. TED speakers have included Bill Gates,
            Jane Goodall, Elizabeth Gilbert and Sir Richard Branson
          </p>
        </motion.div>

        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          {/* <AnimationTitles title="What is TEDx?" className="title" /> */}
          <h3 className="text-white text-lg">What is TEDx?</h3>
          <p className="text-justify gray-50 mb-5">
            In the spirit of ideas worth spreading, TEDx is a program of local,
            self-organized events that bring people together to share a TED-like
            experience. At a TEDx event, TED Talks video and live speakers
            combine to spark deep discussion and connection. These local,
            self-organized events are branded TEDx, where x = independently
            organized TED event. The TED Conference provides general guidance
            for the TEDx program, but individual TEDx events are self-organized.
            (Subject to certain rules and regulations).
          </p>
        </motion.div>
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h3 className="text-white text-lg">What is TEDxCRCE?</h3>
          {/* <AnimationTitles title="What is TEDxCRCE?" className="title" /> */}
          <p className="text-justify gray-50 mb-5">
            TEDxCRCE brings together a collection of doers, thinkers,
            innovators, explorers, visionaries, teachers, learners and seeks to
            illuminate, inspire, change perceptions, incite action and foster
            new connections. TEDxCRCE strives to recreate the unique experience
            found at TED, where the world's leading thinkers and doers
            congregate to share what they are most passionate about. We aim to
            provide a platform where the smartest thinkers, greatest
            visionaries, and most fascinating teachers will be inspired and will
            have the opportunity to inspire others.
          </p>
        </motion.div>
      </Container>
      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="d-flex flex-column"
        >
          <div className="d-flex">
            <div>
              <img
                src={require("../../images/home/about/galleryperformance1.jpg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../../images/home/about/galleryimg2.jpg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img
                src={require("../../images/home/about/galleryperformance5.jpg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../../images/home/about/adityaraj.jpg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="d-flex flex-column"
        >
          <div className="d-flex">
            <div>
              <img
                src={require("../../images/home/about/galleryperformance3.jpg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../../images/home/about/aboutspeaker2.jpg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img
                src={require("../../images/home/about/galleryperformance4.jpg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../../images/home/about/ajayprabhakar.jpg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="d-flex flex-column"
        >
          <div className="d-flex">
            <div>
              <img
                src={require("../../images/home/about/galleryperformance6.jpg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../../images/home/about/aboutspeaker3.jpg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img
                src={require("../../images/home/about/galleryperformance2.jpg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../../images/home/about/aboutspeaker1.jpg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
        </motion.div>
      </Container>
    </div>
  );
}

export default AboutUs;
