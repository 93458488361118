import React from "react";
import FirstBox from "./firstbox";
import SecondBox from "./secondbox";

const MainSponsor = () => {
  return (
    <>
      <FirstBox />
      <SecondBox />
    </>
  );
};

export default MainSponsor;
