// import { BrowserRouter } from "react-router-dom";
// import AboutUs from "./pages/home/AboutUs";
// import NavBar from "./components/navbar/Navbar";
// import Developers from "./pages/home/Developers";
// import Footer from "./components/footer/Footer";
import Join from "./pages/home/Join";
import { Button, Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import CountDown from "../components/functions/CountDown";
import { motion } from "framer-motion";

// import Loading from "./pages/home/Header";
// // import Partners from "./pages/Partners";
// import Properties from "./pages/home/Properties";
import Subscribe from "./pages/home/Subscribe";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Talks from "./pages/talks/Talks";
import "./index.css";
import NavBar from "./components/navbar/Navbar";
import Counter from "./components/clock/Counter";
import Gallery from "./pages/gallery/Gallery";
import Footer from "./components/footer/Footer";
import Team from "./pages/team/Team";
import MainSponsor from "./pages/sponsor/MainSponsor";
import Contact from "./pages/contact/Contact";
import Speaker from "./pages/speaker/Speaker";
import Register from "./pages/register/Register";

function App() {
  return (
    <>
      <div className="lg:px-12">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/talks" element={<Talks />} />
          <Route path="/partners" element={<MainSponsor />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/teams" element={<Team />} /> */}
          <Route path="/speakers" element={<Speaker />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        <Join />
        <Subscribe />
        <Counter />
        <Footer />
      </div>
    </>
  );
}

export default App;
