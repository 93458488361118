import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import AnimationTitles from "../../components/functions/AnimationTitles";

AOS.init();
export default function FirstBox() {
  return (
    <section className="text-gray-400 body-font p-5 h-screen flex justify-center items-center">
      <div className="text-center">
        {/* <div className="flex w-full mb-10 flex-wrap">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-white lg:w-1/3 lg lg:mb-0 mb-2">
            <AnimationTitles title="Our Sponsors" />
          </h1>
        </div> */}
        {/* Image covering the screen */}
        <div className="mb-10" style={{ position: "relative" }}>
          <img
            src={require("../../images/logo/sponsorImage.png")}
            alt="Cover Image"
            style={{
              width: "1500px",
              height: "650px",
              display: "block",
              marginTop: "20px",
            }}
          />
        </div>
      </div>
    </section>
  );
}
