import { Button, Container } from "react-bootstrap";
import AnimationTitles from "../../components/functions/AnimationTitles";
import Counter from "../../components/clock/Counter";

function Subscribe() {
  return (
    <div className="subscribe">
      <Container>
        <AnimationTitles
          title={`Time left for the Event`}
          className="title text-center mx-auto w-75"
        />
      </Container>
    </div>
  );
}

export default Subscribe;
