import React from "react";

import AboutUs from "./AboutUs";

import Developers from "./Developers";

import Loading from "./Header";
// import Partners from "./pages/Partners";
import Properties from "./Properties";
import "./home.css";
import Herotemp from "./Herotemp";
import Theme from "./Theme";

const Home = () => {
  return (
    <>
      {/* <Loading /> */}
      <Herotemp />
      <Theme />
      <Properties />
      <AboutUs />
      {/* <Developers /> */}
    </>
  );
};

export default Home;
