import React from "react";
import prateek from "../../images/pastspeaker/prateekf.jpg";
import ashok from "../../images/pastspeaker/ashok.jpg";
import aruna from "../../images/pastspeaker/aruna.jpeg";
import tannaz from "../../images/pastspeaker/tannaz.jpg";
import ajay from "../../images/pastspeaker/ajay.jpeg";
import pooja from "../../images/pastspeaker/pooja.png";
import raj from "../../images/pastspeaker/raj.jpg";
import nakash from "../../images/pastspeaker/nakash.jfif";
import AnimationTitles from "../../components/functions/AnimationTitles";

const PastSpeaker2023 = () => {
  return (
    <div>
      <section
        style={{ backgroundColor: "#0e0c0e" }}
        class="text-gray-400 body-font"
      >
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={ashok}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Dr. Ashok Johari
                </h2>
                <p class="mt-1">PAEDIATRIC ORTHOPAEDIC SURGEON</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={tannaz}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Tannaz Irani
                </h2>
                <p class="mt-1">ACTRESS</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={aruna}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Aruna Varanasy
                </h2>
                <p class="mt-1">KUCHIPUDI PERFORMER</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={raj}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Raj Aditya Kapoor
                </h2>
                <p class="mt-1">ENTREPRENEUR</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={nakash}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Nakash Aziz
                </h2>
                <p class="mt-1">SINGER | PRODUCER | COMPOSER</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-70 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={pooja}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Pooja Taparia
                </h2>
                <p class="mt-1">SOCIAL ACTIVIST</p>
              </div>
            </div>

            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-80 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={prateek}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Prateek Sethi
                </h2>
                <p class="mt-1">DESIGNER | PRODUCER | DIRECTOR</p>
              </div>
            </div>
            <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a class="block relative h-80 rounded overflow-hidden">
                <img
                  alt="prateek"
                  class="object-cover object-center w-full h-full block"
                  src={ajay}
                />
              </a>
              <div class="mt-4">
                <h2 class="text-white title-font text-lg font-medium">
                  Ajay Prabhakar
                </h2>
                <p class="mt-1">AUTHOR RESEARCHER | UN CONSULTANT</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PastSpeaker2023;
