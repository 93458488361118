import React from "react";
import AnimationTitles from "../../components/functions/AnimationTitles";

const Register = () => {
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="content-center justify-center"
        >
          <h1 className="text-center sm:text-xl text-2xl font-medium title-font text-white lg:w-1/3 lg:mb-0 mb-4 ">
            <AnimationTitles title="Register for TEDxCRCE" />
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="content-center justify-center"
        >
          <h6 className="text-white mx-2">
            Before registering, make sure to read and comply to the following
            rules and guidelines regarding the event:
          </h6>
        </div>

        <section
          style={{ backgroundColor: "#0e0c0e" }}
          class="text-gray-500 body-font"
        >
          <div class="container flex flex-wrap px-5 py-5 mx-auto items-center">
            <div class="md:w-1/2 md:pr-12 md:py-8   md:mb-0 mb-10 pb-10 ">
              <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                REGISTRATION
              </h1>
              <span></span>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                1. By registering and making a payment, you agree that all
                details provided are accurate.
                <br />
                2. Any discrepancy may result in the cancellation of
                registration.
                <br />
                3. Cancellation and rescheduling of registration are not
                allowed.
                <br />
                4. All tickets are non-refundable, non-transferable, and valid
                for one attendee only.
                <br />
                5. Holder of any duplicate or forged passes/badges/tickets will
                be handed over to the security and/or police personnel.
                <br />
                6. Forging passes is a criminal offense.
              </p>
            </div>

            <div class="md:w-1/2 md:pr-12 md:py-8   md:mb-0 mb-10 pb-10 ">
              <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                SOCIALISE
              </h1>
              <span></span>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                1. Use the official hashtag #TEDxCRCE on social media to talk
                about the event.
                <br />
                2. We urge you to carpool to save fuel and get to know fellow
                attendees before the event.
                <br />
                3. Most importantly, have a great time!
                <br />
                4. Explore networking opportunities by participating in
                organized icebreaker sessions or connecting with speakers and
                fellow attendees during breaks.
                <br />
              </p>
            </div>

            <div class="md:w-1/2 md:pr-12 md:py-8  md:mb-0 mb-10 pb-10">
              <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                SOLICITATION
              </h1>
              <span></span>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                1. The organisers reserve the rights of frisk and restrict
                entry.
                <br />
                2. Please cooperate with the private security and police
                personnel or any agencies for security checks.
                <br />
                3. Any individual might be asked to leave the venue without any
                liability of a refund if there is any behavioural misdemeanour.
                This decision rests with the conference and venue management and
                is final and binding.
                <br />
                4. No Flash Photography or video recording at the venue is
                permitted, under any circumstances
                <br />
                5. Carrying of food items, alcohol, cigarettes, weapons,
                inflammable and banned substances are strictly prohibited.
                <br />
                6. The organiser does not take any responsibility for injuries,
                loss or theft of any personal belongings of the ticket holder.
                <br />
                7. There will be adequate breaks for networking and
                refreshments.
                <br />
                8. No children under the age of 15 will be allowed at the venue
                unaccompanied.
                <br />
                9. All standard venue and attendee rules are to be adhered to by
                attendees.
                <br />
              </p>
            </div>

            <div class="md:w-1/2 md:pr-12 md:py-8  md:mb-0 mb-10 pb-10 ">
              <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
                VENUE
              </h1>
              <span></span>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              >
                1. We urge you to mark your presence at the venue 45 minutes
                prior to event timing to carry out necessary verification. .
                <br />
                2. All attendees must carry e-ticket or its printout for
                verification at the venue.
                <br />
                3. Please carry a valid government identity card to attain a
                conference attendee badge at the venue. Eg: Aadhar card, Voter
                ID, PAN card, etc.
                <br />
                4. The attendees must wear their conference badge throughout the
                conference across all venues
                <br />
                5. Holder of any duplicate or forged passes/badges/tickets will
                be handed over to the security and/or police personnel.
                <br />
                6. Seat numbers will be allotted to each confirmed and verified
                attendee at the entrance.
                <br />
                7. Entry into the auditorium will only start 30 minutes before
                the event timing.
                <br />
                8. The auditorium doors will remain shut during sessions and
                will not open for entry.
                <br />
                9. Attendees reaching late at the venue will not be allowed
                inside the auditorium while the sessions are in progress.
                <br />
                10. Re-entry into the venue is strictly prohibited. Attendees
                will have to submit event badge to the security at exit. The
                same will not be given back.
              </p>
            </div>
          </div>
        </section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              backgroundColor: "#ffd700",
              color: "black",
              padding: "20px",
            }}
          >
            <a href="/" style={{ textDecoration: "none", color: "inherit" }}>
              REGISTER CLOSED
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Register;
