import { Swiper, SwiperSlide } from "swiper/react";
import AnimationTitles from "../../components/functions/AnimationTitles";
import { motion } from "framer-motion";

function Developers() {
  return (
    <div className="developers">
      <div className="container-fluid">
        <AnimationTitles title="Our Sponsors" className="title mx-auto" />
        <p className="gray-50 text-center mb-5">
          Your commitment to our mission is invaluable, and we are truly
          grateful .{" "}
        </p>
        <marquee behavior="" scrollamount="20" direction="right" loop="-1">
          <motion.div
            initial={{ x: -80 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Swiper
              className="mySwiper overflow-none justify-content-start"
              grabCursor={true}
              slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                596: {
                  slidesPerView: 3,
                },
                998: {
                  slidesPerView: 4,
                },
                1198: {
                  slidesPerView: 5,
                },
              }}
            >
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/indianoil10.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Indian Oil</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/bewakoof.png")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Bewakoof</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/chaayos.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Chaayos</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/frapp.png")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Frapp</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/saavn.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Saavn</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/IDBI-Bank-logo.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">IDBI Bank</h6>
                </div>
              </SwiperSlide>
            </Swiper>
          </motion.div>
        </marquee>
        <marquee behavior="" scrollamount="20" direction="left" loop="-1">
          <motion.div
            initial={{ x: 80 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Swiper
              className="mySwiper overflow-none"
              spaceBetween={50}
              grabCursor={true}
              slidesPerView={4}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                596: {
                  slidesPerView: 3,
                },
                1298: {
                  slidesPerView: 5,
                },
              }}
            >
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/indianoil10.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Indian Oil</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/bewakoof.png")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Bewakoof</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/chaayos.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Chaayos</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/frapp.png")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Frapp</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/saavn.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">Saavn</h6>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex justify-content-between align-items-center py-2 px-3">
                  <img
                    className="pe-3 "
                    src={require("../../images/home/sponsors/IDBI-Bank-logo.jpg")}
                    alt="img"
                  />
                  <h6 className="text-white m-0">IDBI Bank</h6>
                </div>
              </SwiperSlide>
            </Swiper>
          </motion.div>
        </marquee>
      </div>
    </div>
  );
}

export default Developers;
