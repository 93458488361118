import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnimationTitles from "../../components/functions/AnimationTitles";

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const result = await emailjs.sendForm(
        "service_i367tgl",
        "template_gezinft",
        form.current,
        "_M8Ciw1iM23nt2K3X"
      );

      console.log(result.text);

      // Show success toast
      toast.success("Email sent successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });

      // Clear form values on success
      setFormValues({
        user_name: "",
        user_email: "",
        message: "",
      });
    } catch (error) {
      console.error(error.text);
      toast.error("Error sending email. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (

        <>
        <section class="text-gray-400 bg-black-900 body-font ">
  <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap ">
    <div class="lg:w-3/5 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
      <iframe width="100%" height="100%" title="map" class="absolute inset-0" frameborder="0" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3771.4435597771976!2d72.8187328!3d19.044226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9410830616d%3A0x111b63353dbbce01!2sFr.%20Conceicao%20Rodrigues%20College%20of%20Engineering!5e0!3m2!1sen!2sin!4v1705155485893!5m2!1sen!2sin" style={{filter: 'grayscale(1) contrast(1.2) opacity(0.7)'}}></iframe>
      <div class="bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md">
        <div class="lg:w-1/2 px-6">
          <h2 class="title-font font-semibold text-white tracking-widest text-xs">ADDRESS</h2>
          <p class="mt-1">Fr. Agnel Ashram, Bandstand Promenade, Mount Mary, Bandra West, Mumbai, Maharashtra 400050</p>
        </div>
        <div class="lg:w-1/2 px-6  lg:mt-0">
          <h2 class="title-font font-semibold text-white tracking-widest text-xs">EMAIL</h2>
          <p class="text-yellow-400 leading-relaxed">tedxcrce@gmail.com</p>
          
        </div>
      </div>
    </div>
    
    <div class="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto  md:py-8 mt-8 md:mt-0">
    <h1 className="text-white text-3xl mb-2 font-large title-font ">
         <AnimationTitles title="Contact Us" />
       </h1>
      <p class="leading-relaxed mb-5">Need more help? We're here. Just send us a message or email tedxcrce@gmail.com</p>
      
      <form ref={form}>
      <div class="relative mb-4">

        <label for="name" class="leading-7 text-sm text-gray-400">Name</label>
        <input type="text" id="name" value={formValues.user_name} onChange={handleInputChange} name="user_name" class="w-full bg-gray-800 rounded border border-gray-700 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="email" class="leading-7 text-sm text-gray-400">Email</label>
        <input type="email" id="email" value={formValues.user_email} onChange={handleInputChange} name="user_email" class="w-full bg-gray-800 rounded border border-gray-700 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="message" class="leading-7 text-sm text-gray-400">Message</label>
        <textarea value={formValues.message} onChange={handleInputChange} id="message" name="message" class="w-full bg-gray-800 rounded border border-gray-700 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button type="submit" value={isSubmitting ? "Sending..." : "Send"} disabled={isSubmitting} onClick={sendEmail} class="text-white bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-lg">Submit</button>
      </form>
    </div>
    
  </div>
</section>
        </>

  
  );
};

export default Contact;
