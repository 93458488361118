// import React from "react";

// const TalksCard = ({ item }) => {
//   return (
//     <div className="flex flex-wrap justify-center">
//       {item.map((val) => (
//         <div
//           key={val.id}
//           className="max-w-xs sm:max-w-md md:max-w-sm lg:max-w-xs m-4"
//         >
//           <div className=" p-4 rounded-lg shadow-md">
//             <div>
//               {/* Existing iframe for YouTube videos */}
//               <iframe
//                 className="scale-100 w-full"
//                 src={val.video_src}
//                 title="YouTube video player"
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                 allowFullScreen
//               ></iframe>
//             </div>
//             <h2 className="text-2xl font-semibold text-red-700 uppercase mt-2">
//               {val.speaker_name}
//             </h2>
//             <p className="text-white">{val.title}</p>
//             {/* <p className="text-gray-600">{val.description}</p> */}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default TalksCard;
import React from "react";
import { useTrail, animated } from "react-spring";

const TalksCard = ({ item }) => {
  const trail = useTrail(item.length, {
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { mass: 1, tension: 300, friction: 20 },
    delay: 200,
  });

  return (
    <div className="flex flex-wrap justify-center">
      {trail.map((style, index) => (
        <animated.div
          key={item[index].id}
          className="max-w-xs sm:max-w-md md:max-w-sm lg:max-w-xs m-4"
          style={style}
        >
          <div className="p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div>
              {/* Existing iframe for YouTube videos */}
              <iframe
                className="scale-100 w-full"
                src={item[index].video_src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <h2
              style={{ color: "#ffc107" }}
              className="text-2xl font-semibold  uppercase mt-2"
            >
              {item[index].speaker_name}
            </h2>
            <p className="text-white">{item[index].title}</p>
            {/* <p className="text-gray-600">{item[index].description}</p> */}
          </div>
        </animated.div>
      ))}
    </div>
  );
};

export default TalksCard;
